import { socket, SocketContext } from "./context/socket";
import { Display } from "./pages/Display";
import { Router } from "@reach/router";
import { Remote } from "./pages/Remote";

function App() {
  return (
    <SocketContext.Provider value={socket}>
      <Router>
        <Remote path="/" />
        <Display path="/display" />
      </Router>
    </SocketContext.Provider>
  );
}

export default App;
