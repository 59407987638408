import { RouteComponentProps } from "@reach/router";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { logoApi } from "../api";
import { Label } from "../components/Label";
import styles from "./pages.module.css";

export type LabelData = {
  id: string;
  label: string;
  color: string;
};

export const Remote: React.FC<RouteComponentProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<LabelData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await logoApi.get<
        void,
        AxiosResponse<{ labels: LabelData[] }>
      >("/labels");
      setIsLoading(false);
      setData(data.labels);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div className={styles.remote}>Laster...</div>;
  } else {
    return (
      <div className={styles.remote}>
        {data.map((l, i) => (
          <div>
            <Label key={i} {...l} />
          </div>
        ))}
        <div className={styles.top}>
          <img src="/ITERATE.png" alt="iterate" />
          <div className={styles.links}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.poption.com/companies/iterate/postings/0Qqf8e"
            >
              JOBB?
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.poption.com/companies/iterate/postings/0V1fm8"
            >
              BLI KJENT?
            </a>
          </div>
        </div>
      </div>
    );
  }
};
