import React, { useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { LabelData } from "../pages/Remote";
import styles from "../pages/pages.module.css";
import { nanoid } from "nanoid";

export const Label: React.FC<LabelData> = (props) => {
  const { label, color } = props;
  const socket = useContext(SocketContext);

  const emitLabel = () => {
    socket.emit("add label", { ...props, id: nanoid() });
  };

  return (
    <button
      className={styles.remoteButton}
      onClick={() => emitLabel()}
      style={{ background: color }}
    >
      {label.toUpperCase()}
    </button>
  );
};
