import React from "react";

export const useWindowSize = (): { [T: string]: number } => {
  const getSize = (): { [T: string]: number } => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [windowSize, setWindowSize] = React.useState(getSize);
  React.useLayoutEffect(() => {
    const onResize = () => setWindowSize(getSize);
    window.addEventListener("resize", onResize);
    return (): void => window.removeEventListener("resize", onResize);
  }, []);
  return windowSize;
};

export type useDvdScreenSaverParams = {
  speed?: number;
  startX?: number;
  startY?: number;
};

export function useDvdScreenSaver(params: useDvdScreenSaverParams) {
  const { speed = 0.15, startY = 0, startX = 0 } = params;

  const requestRef: any = React.useRef<any>({});
  const previousTimeRef: any = React.useRef<number>();
  const childRef = React.useRef<any>();
  const parentRef = React.useRef<any>();
  const [positionX, setPositionX] = React.useState(startX);
  const [positionY, setPositionY] = React.useState(startY);
  const [impactCount, setImpactCount] = React.useState<number>(0);

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  React.useEffect(() => {
    if (parentRef.current && childRef.current) {
      requestRef.current.parentWidth =
        parentRef.current?.getBoundingClientRect().width;
      requestRef.current.parentHeight =
        parentRef.current?.getBoundingClientRect().height;
      requestRef.current.childWidth =
        childRef.current?.getBoundingClientRect().width;
      requestRef.current.childHeight =
        childRef.current?.getBoundingClientRect().height;
      requestRef.current.impactCount = requestRef.current.impactCount || 0;
    }
  }, [parentRef, requestRef, childRef, windowWidth, windowHeight]);

  const animate = (time: number) => {
    if (previousTimeRef.current !== undefined) {
      const move = (time - previousTimeRef.current) * speed;
      const parentWidth = requestRef.current.parentWidth;
      const parentHeight = requestRef.current.parentHeight;
      const width = requestRef.current.childWidth;
      const height = requestRef.current.childHeight;
      const setPosX = (prevPos: number) => {
        const positionWithinRange = Math.min(
          Math.max(prevPos, 0),
          parentWidth - width
        );
        if (positionWithinRange >= parentWidth - width) {
          requestRef.current.isMovingRight = false;
          requestRef.current.impactCount = requestRef.current.impactCount + 1;
        }
        if (positionWithinRange <= 0) {
          requestRef.current.isMovingRight = true;
          requestRef.current.impactCount = requestRef.current.impactCount + 1;
        }
        return requestRef.current.isMovingRight
          ? positionWithinRange + move
          : positionWithinRange - move;
      };
      const setPosY = (prevPos: number) => {
        const positionWithinRange = Math.min(
          Math.max(prevPos, 0),
          parentHeight - height
        );
        if (positionWithinRange >= parentHeight - height) {
          requestRef.current.isMovingDown = false;
          requestRef.current.impactCount = requestRef.current.impactCount + 1;
        }
        if (positionWithinRange <= 0) {
          requestRef.current.isMovingDown = true;
          requestRef.current.impactCount = requestRef.current.impactCount + 1;
        }
        return requestRef.current.isMovingDown
          ? positionWithinRange + move
          : positionWithinRange - move;
      };
      setPositionX(setPosX);
      setPositionY(setPosY);
    }
    setImpactCount(requestRef.current.impactCount);
    previousTimeRef.current = time;
    requestRef.current.animte = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    if (childRef.current) {
      childRef.current.style.transform = `translate(${positionX}px, ${positionY}px)`;
    }
  }, [positionX, positionY]);

  const onVisibilityChange = () => {
    requestRef.current.isVisible = !document.hidden;
    if (document.hidden) {
      cancelAnimationFrame(requestRef.current.animte);
    } else {
      requestRef.current.animte = requestAnimationFrame(animate);
    }
  };

  React.useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange, false);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [onVisibilityChange]);

  React.useLayoutEffect(() => {
    if (requestRef.current) {
      requestRef.current.animte = requestAnimationFrame(animate);
    }
    return () => {
      cancelAnimationFrame(requestRef.current.animte);
    };
  }, [requestRef]);

  return { parentRef, childRef, impactCount };
}
