import React from "react";
import { LabelData } from "../pages/Remote";
import styled from "styled-components";
import { useDvdScreenSaver } from "./useDvdScreensaver";

export type DisplayLabel = LabelData & {
  speed: number;
  top: number;
  left: number;
};

export const BouncingItem: React.FC<DisplayLabel> = (props) => {
  const { label, color, speed, top, left } = props;
  const { parentRef, childRef, impactCount } = useDvdScreenSaver({
    speed,
    startX: left,
    startY: top,
  });

  if (impactCount > 12) return null;

  return (
    <Parent ref={parentRef}>
      <Label ref={childRef} style={{ width: "fit-content", background: color }}>
        {label}
      </Label>
    </Parent>
  );
};

const Parent = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
`;

const Label = styled.div`
  font-size: 64px;
  position: absolute;
  padding: 40px 80px;
  text-transform: uppercase;
  border-radius: 10px;
`;
