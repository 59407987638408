import socketIOClient from "socket.io-client";
import React from "react";

export const socketBackendUrl = () => {
  if (window.location.hostname === "frontend.splash.test.iterate.no") {
    return "https://backend.splash.test.iterate.no";
  } else if (window.location.hostname === "frontend.splash.app.iterate.no") {
    return "https://backend.splash.app.iterate.no";
  } else {
    return "localhost:3030";
  }
};

export const socket = socketIOClient(socketBackendUrl(), {
  withCredentials: true,
});

export const SocketContext = React.createContext(socket);
