import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { SocketContext } from "../context/socket";
import { LabelData } from "./Remote";
import { BouncingItem, DisplayLabel } from "../components/BouncingItem";
import styles from "./pages.module.css";
import { nanoid } from "nanoid";

function randRage(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const Display: React.FC<RouteComponentProps> = (props) => {
  const socket = useContext(SocketContext);
  const [connected, setConnected] = useState(socket.connected);

  const wW = window.innerWidth;
  const wH = window.innerHeight;

  const [labels, setLabels] = useState<DisplayLabel[]>([]);

  useEffect(() => {
    socket.on("connect", () => {
      setConnected(true);
    });

    socket.on("label added", (data) => {
      //console.log("Get data: ", data);
      setLabels((currentLabels) => [
        ...currentLabels,
        {
          ...data,
          id: nanoid(),
          speed: randRage(1, 3) / 10,
          left: randRage(200, wW - 200),
          top: randRage(60, wH - 60),
        },
      ]);
    });
  }, []);

  return (
    <div className={styles.display}>
      <div className={styles.bg}>
        {labels.map((label) => (
          <BouncingItem key={label.id} {...label} />
        ))}
      </div>
    </div>
  );
};
