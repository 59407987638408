import axios from "axios";

export const backendUrl = (() => {
  if (window.location.hostname === "frontend.splash.test.iterate.no") {
    return "https://backend.splash.test.iterate.no";
  } else if (window.location.hostname === "frontend.splash.app.iterate.no") {
    return "https://backend.splash.app.iterate.no";
  } else {
    return "http://localhost:3030";
  }
})();

export const logoApi = axios.create({
  baseURL: backendUrl,
});
